import React, { useState, useEffect, useRef } from "react";
import "./ScrollIcon.css";
import mouse from "../../../assets/icons/mouse.svg";

const ScrollIcon: React.FC = () => {
  return (
    <>
      <div className="scrollIcon-container">
        <img src={mouse} alt="mouse image" />
        <div className="scrollIcon-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="36"
            height="36"
            fill="white"
          >
            <path d="M12 16l-6-6h12l-6 6z" />
          </svg>
        </div>
      </div>
    </>
  );
};

export default ScrollIcon;
