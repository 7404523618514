import { GlobalStore } from "../interfaces/store";

const GlobalInitStore: GlobalStore = {
  user: {
    userAddress: "",
    userInkiiBalance: "",
    userKiiBalance: "",
    userNFTs: [],
    shipName: "",
    provider: undefined,
    signer: undefined,
  },
  application: {
    error: false,
    errorMessage: "",
    loading: false,
    success: false,
    section: "",
    preSignUp: {
      register: false,
    },
    console: {
      isOpen: false,
      stage: 0,
      phasePercentage: 0,
    },
    missions: [
			{
				id:"follow",
				icon: "/icons/astronaut-icon.svg",
				description: "Follow US On X Join Our Telegram Join Our Discord",
				frequency: "*Once",
				reward: "0,5 KII",
				completed: true,
			},
			{
				id:"faucet",
				icon: "/icons/faucet-icon.svg",
				description: "Claim Testnet INKII (Faucet)",
				frequency: "*Once Every 24 hours",
				reward: "0,1 KII",
                iconOffset: 55
			},
			{
				id:"mothership",
				icon: "/icons/mothership-icon.svg",
				description: "Send INKII To The Mothership",
				frequency: "*Once Every 3 Days",
				reward: "0,1 KII",
                iconOffset: 105
			},
			{
				id:"stake",
				icon: "/icons/stake-icon.svg",
				description: "Stake KII",
				frequency: "*Once Every week",
				reward: "0,5 KII",
                iconOffset: 55
			},
			{
				id:"swap",
				icon: "/icons/swap-icon.svg",
				description: "Swap KII",
				frequency: "*Once Every 3 Days",
				reward: "0,1 KII",
                iconOffset: 45
			},
			{
				id:"retweet",
				icon: "/icons/galactic-tweet-icon.svg",
				description: "Retweet The Galactic Tweet",
				frequency: "*Once Every 3 Days",
				reward: "0,1 KII",
				iconOffset: 55
			},
			{
				id:"meteor",
				icon: "/icons/meteor-icon.svg",
				description: "Meteor Shower",
				frequency: "*Once Every week",
				reward: "0,3 KII",
				iconOffset: 55
			},
			{
				id:"hyperdrive",
				icon: "/icons/hyperdrive-icon.svg",
				description: "Ship Hyperdrive",
				frequency: "*Everyday Up To 50 Transactions",
				reward: "0,1 KII",
				iconOffset: 75
			},
			{
				id:"land",
				icon: "/icons/planet-kiiex-icon.svg",
				description: "Land On Planet KIIEX",
				frequency: "*Once",
				reward: "1 KII",
				bonus: true,
				iconOffset: 50
			},
			{
				id:"orbital",
				icon: "/icons/orbital-icon.svg",
				description: "Orbital Constructs",
				frequency: "*Once",
				reward: "2 KII",
				bonus: true,
				iconOffset: 65
			},
		],
    inkiiContract: undefined,
    nftContract: undefined,
    language: "en",
  },
};

export default GlobalInitStore;
