import { useContext } from "react";
import "./WalletInfo.css";
import ConnectWallet from "../../common/connectWallet/ConnectWallet";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { ethers } from "ethers";

const WalletInfo: React.FC = () => {
	const { globalStore } = useContext(GlobalContext);

	function formatAddress(address: string): string {
		if (!address) {
			return "";
		}

		if (address.length <= 12) {
			return address; // Si la dirección es más corta o igual a 12 caracteres, no la trunques.
		}

		const start = address.slice(0, 6); // Obtiene los primeros 6 caracteres
		const end = address.slice(-6); // Obtiene los últimos 6 caracteres

		return `${start}.....${end}`; // Combina con los puntos suspensivos en el medio
	}

	function formatKiiBalance(balance: string): string {
    let formatedBalance = ethers.formatEther(balance);
    let [integerPart, decimalPart] = formatedBalance.split(".")
		return integerPart+"."+decimalPart.substring(0, 4);;
	}

	return (
		<div className="wallet-info-container">
			<div className="title-container">Account</div>

			{/* <div className="account">Account</div> */}

			<div className="description-container">
				<span className="ship-name">{globalStore?.user.shipName}</span>
				<span className="address">
					Wallet <span>{formatAddress(globalStore?.user.userAddress)}</span>{" "}
				</span>
				{globalStore?.user.userKiiBalance && (
					<div className="connect-wallet-button balance">
						{formatKiiBalance(globalStore.user.userKiiBalance)} KIIs
					</div>
				)}
			</div>
			<ConnectWallet className="connect-wallet-button"></ConnectWallet>
		</div>
	);
};

export default WalletInfo;
