import "./Footer.css";

/* Logos de RRSS para el footer */
import kiiglobal from "../../../assets/icons/kiichain-imagotipo.svg";
import galxe from "../../../assets/icons/kiichain-galxe.svg";
import x from "../../../assets/icons/kiichain-x.svg";
import telegram from "../../../assets/icons/kiichain-telegram.svg";
import medium from "../../../assets/icons/kiichain-medium.svg";
import instagram from "../../../assets/icons/kiichain-instagram.svg";
import discord from "../../../assets/icons/kiichain-discord.svg";
import zealy from "../../../assets/icons/kiichain-zealy.svg";
import linkedin from "../../../assets/icons/kiichain-linkedin.svg";
import facebook from "../../../assets/icons/kiichain-facebook.svg";
import youtube from "../../../assets/icons/kiichain-youtube.svg";
import github from "../../../assets/icons/kiichain-github.svg";
import taskon from "../../../assets/icons/kiichain-taskon.svg";
import questn from "../../../assets/icons/kiichain-questn.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <section className="Kiichain-footer-container">
      <div className="Footer-container">
        <section className="Footer-container-blocks">
          <img src={kiiglobal} alt="Logo de KiiChain" />
          <div className="Footer-container-blocks-column">
            <h3>{t("footer.explore")}</h3>
            <a href="https://blog.kiiglobal.io/" target="_blank">
              Blog KiiChain
            </a>
            <a href="mailto:soporte@kiiglobal.io" target="_blank">
              Support
            </a>
            <a href="https://docs.kiiglobal.io/docs" target="_blank">
              Docs
            </a>
            <a href="https://github.com/KiiChain" target="_blank">
              GitHub
            </a>
          </div>
          <div className="Footer-container-blocks-column">
            <h3>{t("footer.ecosystem")}</h3>
            <a href="https://app.kiichain.io/kiichain" target="_blank">
              KiiChain Explorer
            </a>
            <a
              href="https://apps.apple.com/us/app/kii-mobile/id6474740411"
              target="_blank"
            >
              Kii Wallet App Store
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.kiimobile"
              target="_blank"
            >
              Kii Wallet Play Store
            </a>
            <a href="https://wallet.kiichain.io/" target="_blank">
              Kii Wallet Web
            </a>
          </div>
          <div className="Footer-container-blocks-column">
            <h3>{t("footer.aboutKii")}</h3>
            <a href="https://kiichain.io/Tokenomics">Tokenomics</a>
            <a href="https://kiichain.io/verify">Kii Verify</a>
            <a href="https://kiichain.io/ekiipo">Ekiipo</a>
          </div>
        </section>
        <figure className="Footer-container-icons">
          <a href="https://x.com/KiiChain_" target="_blank">
            <img src={x} alt="Cuenta de KiiChain en Twitter" />
          </a>
          <a href="https://discord.gg/kiichain" target="_blank">
            <img src={discord} alt="Cuenta de KiiChain en Discord" />
          </a>
          <a href="https://t.me/KiiChainGlobal" target="_blank">
            <img src={telegram} alt="Cuenta de KiiChain en Telegram" />
          </a>
          <a href="https://github.com/KiiChain" target="_blank">
            <img src={github} alt="Cuenta de KiiChain en Github" />
          </a>
          <a href="https://taskon.xyz/space/2882950" target="_blank">
            <img src={taskon} alt="Cuenta de KiiChain en TaskOn" />
          </a>
          <a href="https://app.galxe.com/quest/KiiChain" target="_blank">
            <img src={galxe} alt="Cuenta de KiiChain en Galxe" />
          </a>
          <a href="https://zealy.io/cw/kiichain/questboard" target="_blank">
            <img src={zealy} alt="Cuenta de KiiChain en Zealy" />
          </a>
          <a href="https://app.questn.com/KiiChain" target="_blank">
            <img src={questn} alt="Cuenta de KiiChain en QuestN" />
          </a>
          <a href="https://medium.com/@kiichain" target="_blank">
            <img src={medium} alt="Cuenta de KiiChain en Medium" />
          </a>
          <a href="https://www.youtube.com/@kiichain_" target="_blank">
            <img src={youtube} alt="Cuenta de KiiChain en Youtube" />
          </a>
          <a href="https://www.linkedin.com/company/kiiglobal" target="_blank">
            <img src={linkedin} alt="Cuenta de KiiChain en LinkedIn" />
          </a>
          <a href="https://www.instagram.com/kiichainofficial" target="_blank">
            <img src={instagram} alt="Cuenta de KiiChain en Instagram" />
          </a>
          <a href="https://www.facebook.com/Kiichainglobal/" target="_blank">
            <img src={facebook} alt="Cuenta de KiiChain en Facebook" />
          </a>
        </figure>
      </div>
    </section>
  );
};

export default Footer;
