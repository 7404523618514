import { useContext } from "react";
import "./NFTDisplayer.css";
import { GlobalContext } from "../../../contexts/GlobalContext";

const NFTDisplayer: React.FC = () => {
  const { globalStore } = useContext(GlobalContext);

  return (
    <div className="nft-container">
      {globalStore?.user.userNFTs && (
        <>
          <div className="tiltle">My NFTs</div>
          {globalStore?.user.userNFTs.length > 0 && (
            <div className="nft-list">
              {globalStore?.user.userNFTs.map((nft: any, index) => (
                <div key={index} className="nft">
                  <img src={nft.tokenURI} />
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NFTDisplayer;
