import "./Modal.css";

interface props {
  title: string;
  content: string;
}

const Modal: React.FC<props> = ({ title, content }) => {
  return (
    <div className="modal-container">
      <h2>{title}</h2>
      <p>{content}</p>
    </div>
  );
};

export default Modal;
