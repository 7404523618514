import { useTranslation } from "react-i18next";
import "./TitleComing.css";

const TitleComing: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="titleContainer">
      <p className="reward-message-highlight upper">
        {t("coming.title.titleHigher")}
      </p>
      <p className="reward-message-highlight lower">
        {t("coming.title.titleLower")}
      </p>
    </div>
  );
};

export default TitleComing;
