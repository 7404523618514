import React, { useContext, useEffect, useState } from "react";
import "./PhaseAnnounce.css";
import { useContract } from "../../../hooks/useContract";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import copyIcon from "../../../assets/icons/copy_paste.svg";

const PhaseAnnounce: React.FC = () => {
  const { t } = useTranslation();
  const { getUser } = useContract();
  const [referralMessage, setReferralMessage] = useState<string>(
    t("coming.phaseAnnounce.createReferralCode")
  );
  const [copied, setCopied] = useState(false);
  const { globalStore } = useContext(GlobalContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (globalStore.user.userAddress === "") {
      navigate("/");
    }
  }, []);

  const handleReferralCode = async () => {
    try {
      const data = await getUser(globalStore.user.userAddress);
      if (data?.agreeTerms) {
        setReferralMessage(data.referralCode);
        try {
          await navigator.clipboard.writeText(data.referralCode);
          setCopied(true);

          setTimeout(() => {
            setCopied(false);
          }, 2000);
        } catch (err) {
          console.error("Error copying the text: ", err);
        }
      }
    } catch (error) {
      console.error("Error trying to get the Referral code: ", error);
    }
  };

  return (
    <div className="phase-announcement-container">
      <div className="announcement-text">
        <p className="announcement-highlight">
          {t("coming.phaseAnnounce.highlightTitle1")}
        </p>
        <p className="announcement-highlight">
          {t("coming.phaseAnnounce.highlightTitle2")}
        </p>

        <p className="announcement-description">
          {t("coming.phaseAnnounce.description")}
        </p>
      </div>

      <button className="custom-button" onClick={handleReferralCode}>
        <div className="custom-button-text-container">{referralMessage}</div>
        {referralMessage.includes("-") && (
          <img src={copyIcon} alt="copy paste icon" />
        )}
      </button>

      {copied && (
        <div className="copy-notification">
          {t("coming.phaseAnnounce.codeCopied")}
        </div>
      )}
    </div>
  );
};

export default PhaseAnnounce;
