import { useTranslation } from "react-i18next";
import "./FirstMission.css";
import XIcon from "../../../assets/icons/X_icon.svg";

interface props {
  setShowFirstMission: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenBanditModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const FirstMission: React.FC<props> = ({
  setShowFirstMission,
  setOpenBanditModal,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="firstMission-container">
        {/* Background image */}
        <div className="firstMission-background"></div>
        <div className="firstMission-background-blur"></div>
        {/* Close Icon */}
        <div
          className="firstMission-closeIcon"
          onClick={() => setShowFirstMission(false)}
        >
          {"(x)"}
        </div>
        {/* Content  */}
        <div className="firstMission-content">
          <p className="firstMission-content-title">
            {t("coming.firstMission.title")}
          </p>

          <p className="firstMission-content-description">
            {t("coming.firstMission.description1")}
            <span className="content-highlight"> X </span>
            {t("coming.firstMission.description2")}
          </p>

          <button
            className="firstMission-content-button"
            onClick={() => {
              setShowFirstMission(false);
              // setOpenBanditModal(true);
              window.open("https://x.com/kiichain_", "_blank");
            }}
          >
            {t("coming.firstMission.button")}
            <img src={XIcon} alt="X icon" />
          </button>

          <p className="firstMission-footer-message">
            {t("coming.firstMission.footer-message1")}
          </p>

          <p className="firstMission-footer-message firstMission-footer-reward">
            {t("coming.firstMission.footer-message2")}
          </p>

          <p className="firstMission-footer-highlight">
            {t("coming.firstMission.footer-message3")}
          </p>
        </div>
      </div>
    </>
  );
};

export default FirstMission;
