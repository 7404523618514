import { BanditContextProvider } from "@bandit-network/react";
import { ReactNode } from "react";

enum SupportedChains {
  Solana = "solana",
  Evm = "evm",
}

export const BanditProvider = ({ children }: { children: ReactNode }) => {
  return (
    <BanditContextProvider
      apiKey="5c961e83144844e2aec686c5c44b69ae"
      cluster="mainnet"
      appearance="dark"
      walletSettings={{
        enabledChains: [SupportedChains.Evm],
      }}
    >
      {children}
    </BanditContextProvider>
  );
};
