import { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";

interface FormState {
  shipName: string;
  walletAddress: string;
  referralCode: string;
  agreeTerms: boolean;
}

interface FormErrors {
  shipName?: string;
  walletAddress?: string;
  referralCode?: string;
  agreeTerms?: string;
}

export const validateForm = (formData: FormState): FormErrors => {
  const errors: FormErrors = {};

  if (!formData.shipName) errors.shipName = "Ship Name is required";
  if (!formData.walletAddress)
    errors.walletAddress = "Wallet address is required";
  if (formData.agreeTerms !== true)
    errors.agreeTerms = "You must agree to the terms";

  return errors;
};

export function generateReferralCode(
  shipName: string,
  walletAddress: string
): string {
  // Add the last 3 elements of the wallet
  const latest = walletAddress.slice(-3);
  const referralCode = shipName + "-" + latest;

  return referralCode;
}
