import React from "react";
import "./RewardMessage.css"; // Importa el archivo CSS
import { useTranslation } from "react-i18next";

interface props {
  setShowFirstMission: React.Dispatch<React.SetStateAction<boolean>>;
}

const RewardMessage: React.FC<props> = ({ setShowFirstMission }) => {
  const { t } = useTranslation();

  return (
    <div className="reward-message-container">
      <div
        className="reward-text-group"
        onClick={() => setShowFirstMission(true)}
      >
        <p className="reward-message-text">
          {t("coming.rewardMessage.rewardMessage1")}
        </p>
        <p className="reward-message-text">
          {t("coming.rewardMessage.rewardMessage2")}
        </p>
        <p className="reward-message-highlight">
          {t("coming.rewardMessage.rewardMessage3")}
        </p>
        <p className="reward-message-subtext">
          {t("coming.rewardMessage.rewardMessage4")}
        </p>
      </div>
    </div>
  );
};

export default RewardMessage;
