import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/common/header/Header";
import "./ComingSoonPage.css";
import PhaseAnnounce from "../../components/comingSoon/anounce/PhaseAnnounce";
import RewardMessage from "../../components/comingSoon/rewardMessage/RewardMessage";
import PhaseBoxGroup from "../../components/comingSoon/phases/phaseBoxGroup/PhaseBoxGroup";
import Footer from "../../components/common/footer/Footer";
import TitleComing from "../../components/comingSoon/title/TitleComing";
import ScrollIcon from "../../components/common/scrollIcon/ScrollIcon";
import FirstMission from "../../components/comingSoon/firstMission/FirstMission";
import { Campaign } from "@bandit-network/react";

const ComingSoonPage: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  const footerRef = useRef<HTMLDivElement>(null);
  const [showFirstMission, setShowFirstMission] = useState<boolean>(false);
  const [openBanditModal, setOpenBanditModal] = useState<boolean>(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(false);
          } else {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  // Turn off Scroll icon when first mission's modal is openned
  useEffect(() => {
    if (showFirstMission) {
      setIsVisible(false);
      return;
    }
    setIsVisible(true);
  }, [showFirstMission]);

  return (
    <div className="coming-soon-page-container">
      <div className="background-coming"></div>
      <div className="header-container">
        <Header />
      </div>
      <TitleComing />
      <div className="coming-container">
        <PhaseAnnounce />
        <RewardMessage setShowFirstMission={setShowFirstMission} />
      </div>

      {/* Scroll icon */}
      {isVisible && <ScrollIcon />}
      <PhaseBoxGroup />

      {/* Footer */}
      <div className="footer-container-page" ref={footerRef}>
        <Footer />
      </div>

      {/* First mission pop-up */}
      {showFirstMission && (
        <>
          <div className="modal-overlay"></div>
          <FirstMission
            setShowFirstMission={setShowFirstMission}
            setOpenBanditModal={setOpenBanditModal}
          />
        </>
      )}

      {/* Bandit Follow Campain */}
      <div className="bandit-container">
        <Campaign
          campaignId={2185}
          displayMode="modal"
          isOpen={openBanditModal}
          onClose={() => {
            setOpenBanditModal(false);
          }}
        ></Campaign>
      </div>
    </div>
  );
};

export default ComingSoonPage;
