import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import "./MissionModal.css";
import closeIcon from "../../../assets/icons/kiichain-close.svg";

interface props {
	background: string;
	setShowModal: Dispatch<SetStateAction<boolean>>;
	textElements?: IModalTextElements;
	foregroundVisualElement?: IVisualElement[];
}

export interface IModalTextElements {
	left?: string;
	top?: string;
	width: string;

	title: string;
	description: string;
	frequency: string;
	reward: string;
	buttons: React.ReactNode[];
}

export interface IVisualElement {
	url: string;
	top?: string;
	left?: string;
	bot?: string;
	width?: string;
	height?: string;
	z?: string;
}

const MissionModal: React.FC<props> = ({
	background,
	setShowModal,
	textElements,
	foregroundVisualElement,
}) => {

	const [applyMarginLeft, setApplyMarginLeft] = useState(true);

	// Detectar si el ancho de la pantalla es menor a 500px
	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 1200) {
				setApplyMarginLeft(false); // No aplicar marginLeft si el ancho de la pantalla es menor a 500px
			} else {
				setApplyMarginLeft(true); // Aplicar marginLeft si el ancho es mayor o igual a 500px
			}
		};

		// Ejecutar la función en la carga inicial
		handleResize();

		// Añadir event listener para escuchar cambios de tamaño de la ventana
		window.addEventListener('resize', handleResize);

		// Limpiar el event listener cuando el componente se desmonta
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);
	return (
		<div className="mission-modal-container">
			<div className="visual-elements-container">
				{foregroundVisualElement &&
					foregroundVisualElement.map((element, index) => (
						<img
							key={index}
							src={element.url}
							alt={`foreground-element-${index}`}
							style={{
								top: element.top,
								bottom: element.bot,
								left: element.left,
								position: "absolute",
								width: element.width,
								height: element.height,
								zIndex: element.z,
							}}
						/>
					))}
			</div>
			<div
				className="mission-modal"
				style={{ backgroundImage: `url(${background})` }}
			>
				<div className="close-container" onClick={() => setShowModal(false)}>
					<img src={closeIcon} alt="close-icon" />
				</div>
				{textElements && (
					<div
						className="text-container"
						style={{
							marginTop: textElements.top,
							width: textElements.width,
							...(applyMarginLeft && { marginLeft: textElements.left }),
							...(!applyMarginLeft && { margin: "0 auto", textAlign: "center" })
						}}
					>
						<div
							className="title"
							dangerouslySetInnerHTML={{ __html: textElements.title }}
						/>
						<div className="description">{textElements.description}</div>
						<div className="buttons-container">
							{textElements.buttons &&
								textElements.buttons.map((button, index) => (
									<div key={index}>{button}</div>
								))}
						</div>
						<div className="frequency">{textElements.frequency}</div>
						<div className="reward">{textElements.reward}</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default MissionModal;
