import bgFaucetMission from "../../assets/images/faucetbg.png";
import bgMothershipMission from "../../assets/images/mothershipbg.png";
import bgSwapMission from "../../assets/images/swapbg.png";
import bgMeteorMission from "../../assets/images/meteorbg.png";
import bgLandMission from "../../assets/images/landbg.png";
import bgOrbitalMission from "../../assets/images/orbitalbg.png";
import veFaucet from "../../assets/animations/faucet.gif";
import veMothership1 from "../../assets/images/naveMadre1.png";
import veMothership2 from "../../assets/animations/mother_ship_1.gif";
import veFonsdoStake from "../../assets/images/Fondo_stake_1.png";
import veMonedacayendo from "../../assets/animations/MONEDA KII CAYENDO GIF.gif";
import veSwap from "../../assets/animations/swap.gif";
import veFondoSwap from "../../assets/images/Fondo_swap_1.png";
import veGalacticTweet from "../../assets/animations/GalacticTweet.gif";
import veAsteroids from "../../assets/animations/ASTEROIDES_1.gif";
import veFondoAstronauts from "../../assets/images/FondoAstronautas.png";
import veAstronauts from "../../assets/animations/astronautas.gif";
import vePlanet from "../../assets/images/XIIEK 1.png"
import veSpaceships from "../../assets/animations/Spaceship kiiex_1.gif"
import veOrbital from "../../assets/animations/Orbital.gif"
import vePlanet2 from "../../assets/images/XIIEK 2.png"
import veHyperdrive from "../../assets/animations/HYPERDRIVE KII.gif"
import {
	IModalTextElements,
	IVisualElement,
} from "../../components/mission/missionModal/MissionModal";
import Button from "../../components/common/button/Button";

export type MissionId =
	| "follow"
	| "faucet"
	| "mothership"
	| "stake"
	| "swap"
	| "retweet"
	| "meteor"
	| "hyperdrive"
	| "land"
	| "orbital";

export const MISSION_MODAL_BACKGROUNDS: Record<MissionId, string> = {
	follow: "",
	faucet: bgFaucetMission,
	mothership: bgMothershipMission,
	stake: "",
	swap: bgSwapMission,
	retweet: "",
	meteor: bgMeteorMission,
	hyperdrive: "",
	land: bgLandMission,
	orbital: bgOrbitalMission,
};

export const MISSION_MODAL_VISUAL_ELEMENTS: Record<
	MissionId,
	IVisualElement[]
> = {
	follow: [
		{
			url: veFondoAstronauts,
			top: "0",
			left: "0",
			width: "auto",//"calc(1920*100/1080 *1vh)",
			height: "100%",//"calc(1080*100/1080 *1vh)",
			z: "-1",
		},
		{
			url: veAstronauts,
			bot:"0",
			left: "0",
			width: "100%",//"calc(1920*100/1080 *1vh)",
			height: "auto",//"calc(1080*100/1080 *1vh)",
			z: "2",
		},
	],
	faucet: [
		{
			url: veFaucet,
			// top: "0px",
			bot: "0",
			left: "0px",
			width: "100%",//"calc(1920*100/1080 *1vh)",
			height: "auto",//"calc(1080*100/1080 *1vh)",
			z: "2",
		},
	],
	mothership: [
		{
			url: veMothership1,
			top: "0px",
			left: "0px",
			width: "100vw",
			z: "2",
		},
		{
			url: veMothership2,
			top: "0",
			left: "0",
			width: "100vw",
			z: "3",
		},
	],
	stake: [
		{
			url: veFonsdoStake,
			top: "0",
			left: "0",
			z: "-2",
		},
		{
			url: veMonedacayendo,
			top: "4px",
			left: "374px",
			width: "calc(2009*100/1080 *1vh)",
			height: "calc(1130*100/1080 *1vh)",
			z: "-1",
		},
	],
	swap: [
		{
			url: veFondoSwap,
			top: "0",
			left: "0",
			z: "-1",
		},
		{
			url: veSwap,
			top: "9px",
			left: "129px",
			width: "calc(635*100/1080 *1vh)",
			height: "calc(572*100/1080 *1vh)",
			z: "2",
		},
		{
			url: veSwap,
			top: "488px",
			left: "1129px",
			width: "calc(635*100/1080 *1vh)",
			height: "calc(572*100/1080 *1vh)",
			z: "2",
		},
	],
	retweet: [
		{
			url: veGalacticTweet,
			top: "calc(-132*100/1080 *1vh)",
			left: "calc(-363*100/1080 *1vh)",
			width: "calc(2646*100/1080 *1vh)",
			height: "calc(1344*100/1080 *1vh)",
			z: "-1",
		},
	],
	meteor: [
		{
			url: veAsteroids,
			top: "0",
			left: "0",
			width: "calc(1920*100/1080 *1vh)",
			height: "calc(1080*100/1080 *1vh)",
			z: "-1",
		},
	],
	hyperdrive: [
		{
			url: veHyperdrive,
			top: "0",
			left: "0",
			width: "calc(1920*100/1080 *1vh)",
			height: "calc(1080*100/1080 *1vh)",
			z: "-1",
		},
	],
	land: [
		{
			url: vePlanet,
			top: "216px",
			left: "298px",
			z: "2",
		},
		{
			url: veSpaceships,
			top: "0",
			left: "0",
			width: "calc(1920*100/1080 *1vh)",
			height: "calc(1080*100/1080 *1vh)",
			z: "3",
		},
	],
	orbital: [
		{
			url: vePlanet2,
			top: "74px",
			left: "426px",
			z: "2",
		},
		{
			url: veOrbital,
			top: "0",
			left: "0",
			width: "calc(1920*100/1080 *1vh)",
			height: "calc(1080*100/1080 *1vh)",
			z: "3",
		},
	],
};

// TODO: Handle buttons
export const MISSION_MODAL_TEXT_ELEMETS: Record<MissionId, IModalTextElements> =
	{
		follow: {
			title: "Community Mission",
			description:
				"Join Telegram and our Discord server to stay up to date with all the updates we will have during missions and reward blasts.",
			width: "calc(741*100/1080 *1vh)",
			frequency: "*Available one time only Reward:",
			reward: "0,5 $KII",
			left: "calc(((1040/2) - (741/2))/1040 *100% )",
			buttons: [
				<Button isGreen={false} text="DISCORD" />,
				<Button isGreen={true} text="TELEGRAM" />,
			],
		},
		faucet: {
			title: "Faucet",
			description:
				"Claim 2,500 INKII and have balance available to send to the Mothership, set Hyperdrive and Swap for $KII tokens",
			width: "calc(434*100/1080 *1vh)",
			left: "calc(500/1040 * 100%)",
			frequency: "*Available every 3 days Reward: ",
			reward: "0.1 $KII",
			buttons: [<Button isGreen={false} text="CLAIM INKII" />],
		},
		mothership: {
			title: "Mother Ship",
			description:
				"Send 2,000 INKII to the Mothership and guarantee your ship’s place in our armada.",
			width: "calc(555*100/1080 *1vh)",
			frequency: "*Available every 3 days Reward: ",
			reward: "0.1 $KII",
			left: "calc(((1040/2) - (555/2))/1040 *100% )", //Center
			top: "100px",
			buttons: [<Button isGreen={true} text="SEND INKII" />],
		},
		stake: {
			title: "Stake $KII",
			description:
				"Staking your earned tokens and Mothership will send you bigger rewards.",
			width: "446px",
			frequency: "*Available Every 7 days Reward:",
			reward: "0,5 $KII",
			left: "calc(((1040/2) - (446/2)) *1px )",
			buttons: [<Button isGreen={true} text="SEND" />],
		},
		swap: {
			title: "Swap $KII",
			description:
				"Swap 500 INKII for $KII token and get your ship into the top 10 most rewarded ships.",
			width: "494px",
			frequency: "*Available every 3 days Reward:",
			reward: "0,1 $KII",
			left: "calc(((1040/2) - (494/2)) *1px )",
			buttons: [<Button isGreen={true} text="Swap INKII" />],
		},
		retweet: {
			title: "Galactic Tweet",
			description:
				"Quote our tweet with your own original text and show your ship's commitment to the missions.",
			width: "427px",
			frequency: "*Available every 3 days Reward:",
			reward: "0,1 $KII",
			left: "calc(((1040/2) - (427/2)) *1px )",
			buttons: [<Button isGreen={true} text="TWEET" />],
		},
		meteor: {
			title: "Meteor Shower",
			description:
				"Answer the questions correctly for each of the 3 meteorites and secure your reward for learning about KiiChain.",
			width: "555px",
			frequency: "*Available every 7 days Reward:",
			left: "calc(((1040/2) - (555/2)) *1px )",
			reward: "0,3 $KII",
			buttons: [<Button isGreen={false} text="START" />],
		},
		hyperdrive: {
			title: "Hyperdrive",
			description: "Send 2,000 INKII to the Mothership and guarantee your ship’s place in our armada.",
			width: "555px",
			left: "calc(((1040/2) - (555/2)) *1px )",
			frequency: "*Available 1 time per month Reward:",
			reward: "1 $KII",
			//TODO: Add amount field
			buttons: [<Button isGreen={false} text="SEND" />],
		},
		land: {
			title: "Planet KIIEX",
			description: "Land on planet KIIEX, register and verify yourself at level 1 in our exchange, and get one of the most generous rewards in this airdrop.",
			width: "424px",
			left: "600px",
			frequency: "*Available every 3 days Reward:",
			reward: "0,1 $KII",
			buttons: [<Button isGreen={false} text="REGISTER" />],
		},
		orbital: {
			title: "Orbital <br> Construct",
			description: "Build something on KiiChain for your ship to receive 1 full $KII for contributing to the development of our ecosystem.",
			width: "471px",
			left: "calc(((1040/2) - (471/2)) *1px )",
			top: "30px",
			frequency: "*Available 1 time per month Reward:",
			reward: "1 $KII",
			//TODO Add field
			buttons: [],
		},
	};
