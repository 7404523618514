import "./MissionBox.css";
import check from "../../../assets/icons/check.svg";
import { Dispatch, SetStateAction } from "react";
import { MissionId } from "../../../pages/missionsPage/MissionsPage.models";
export interface IMission {
	id: MissionId;
	icon: string;
	description: string;
	frequency: string;
	reward: string;
	bonus?: boolean;
	completed?: boolean;
	iconOffset?: number;
}

interface props {
	mission: IMission;
	configModal: (mission: IMission) => void;
}

const MissionBox: React.FC<props> = ({ mission, configModal }) => {
	return (
		<div className="mission-box-wrapper" onClick={() => configModal(mission)}>
			<div
				className="icon"
				style={{
					transform: `translate(0px, ${mission.iconOffset}px)`,
				}}
			>
				<img src={mission.icon} />
			</div>
			<div
				className={`mission-box-container ${
					mission.completed ? "completed" : ""
				}`}
			>
				<div className="mission-box-color">
					<div className="mission-box">
						<div className="mission-description">{mission.description}</div>
						<div className="mission-frequency">{mission.frequency}</div>
						<div className="reward-container">
							<div className="mission-reward">{mission.reward}</div>
							{mission.completed && <img src={check} alt="check" />}
						</div>
					</div>
				</div>
			</div>
			<div className={`bonus-container ${mission.bonus ? "" : "disabled"}`}>
				<div className="bonus-text">Bonus</div>
			</div>
		</div>
	);
};

export default MissionBox;
