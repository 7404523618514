import { useContext, useState } from "react";
import { useContract } from "../../../hooks/useContract";
import { UserData } from "../../../interfaces/entities";
import "./Referal.css";
import { GlobalContext } from "../../../contexts/GlobalContext";

// TODO: getData from contract
const Referal: React.FC = () => {
  const { registerUser, getUser } = useContract();
  const [copied, setCopied] = useState(false);
  const { globalStore } = useContext(GlobalContext);
  const handleReferralCode = async () => {
    try {
      const data = await getUser(globalStore.user.userAddress);
      try {
        await navigator.clipboard.writeText(data!.referralCode);
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      } catch (err) {
        console.error("Error copying the text: ", err);
      }
    } catch (error) {
      console.error("Error trying to get the Referral code: ", error);
    }
  };
  return (
    <div className="referal-container">
      <span className="referal-text">
        Bring more ships and earn. Earn XX KII for every user you invite to
        KII-BOOM!
      </span>
      <button className="referal-button" onClick={handleReferralCode}>
        Create your referral code
      </button>
      {copied && <div className="copy-notification">Code copied!</div>}
    </div>
  );
};

export default Referal;
