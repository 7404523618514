import "./LoginForm.css";
import ConnectWallet from "../../common/connectWallet/ConnectWallet";

const LoginForm: React.FC = () => {
  return (
    <>
      <div className="loginFormContainer">
        <ConnectWallet className="loginFormButton connectWalletButton"></ConnectWallet>
      </div>
    </>
  );
};

export default LoginForm;
