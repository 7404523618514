import { useEffect, useState } from "react";
import { ethers, Signer } from "ethers";
import {
  getProvider,
  getWalletFromPhrase,
  getContract,
} from "../services/kiichain";
import contract from "../abi/contract.json";
import { getSigner } from "../services/kiichain";
import { UserData } from "../interfaces/entities";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS!;
const contractABI = contract;

export function useContract() {
  const [contract, setContract] = useState<ethers.Contract | null>(null);

  useEffect(() => {
    const provider = getProvider();
    const wallet = getWalletFromPhrase(provider);
    const signer = getSigner(wallet, provider);

    const contractInstance = getContract(contractAddress, contractABI, signer);
    setContract(contractInstance);
  }, []);

  const registerUser = async (user: UserData): Promise<boolean> => {
    try {
      const tx = await contract!.registerUser(
        user.agreeTerms,
        user.shipName,
        user.walletAddress,
        user.referralCode,
        user.externalReferralCode
      );
      await tx.wait();
      return true;
    } catch (error) {
      console.error("Error creating the user", error);
      return false;
    }
  };

  const getUser = async (
    walletAddress: string
  ): Promise<UserData | undefined> => {
    try {
      const data = await contract!.getUserData(walletAddress);
      const [
        agreeTerms,
        shipName,
        userAddress,
        referralCode,
        externalReferralCode,
      ] = data;
      const userData: UserData = {
        agreeTerms,
        referralCode,
        shipName,
        walletAddress,
        externalReferralCode,
      };
      return userData;
    } catch (error) {
      console.error("Error creating the user", error);
    }
  };

  const isShipNameTaken = async (
    shipName: string
  ): Promise<boolean | undefined> => {
    try {
      return await contract!.isShipNameTaken(shipName);
    } catch (error) {
      console.error("Error getting whether the shipname is taken", error);
    }
  };

  const getReferredAccounts = async (walletAddress: string) => {
    try {
      return await contract!.getReferredAccounts(walletAddress);
    } catch (error) {
      console.error("Error getting the referred accounts", error);
    }
  };

  return {
    contract,
    registerUser,
    getUser,
    isShipNameTaken,
    getReferredAccounts,
  };
}
