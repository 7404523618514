import { NFT } from "../interfaces/entities";
import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { getContract } from "../services/kiichain";

import nftContract from "../abi/nft.json";
import { setAppNftContract, setUserNFTS } from "../store/globalActions";

export function useNftContract() {
  const contractAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
  const contractABI = nftContract;

  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const { globalStore, globalDispatch } = useContext(GlobalContext);

  useEffect(() => {
    const signer = globalStore.user.signer;
    const contractInstance = getContract(
      contractAddress!,
      contractABI,
      signer!
    );
    setContract(contractInstance);
    globalDispatch(setAppNftContract(contractInstance));
  }, [globalStore.user.signer]);

  const getNftList = async () => {
    try {
      const walletAddress = globalStore.user.userAddress;
      // Get the Ids of my NFTs
      const nfts: BigInt[] = await contract?.tokensByAddress(walletAddress);

      // Get the Token by its Id
      const numberArray = nfts.map((tokenId) => Number(tokenId));

      // Get the NFT by its Id
      const nftList: NFT[] = [];
      for (let index = 0; index < numberArray.length; index++) {
        const tokenId = numberArray[index];
        const price = Number(await contract?.tokenPrices(tokenId));
        const tokenURI = await contract?.tokenURI(tokenId);
        const categories = await contract?.getNFTCategories(tokenId);
        const nft: NFT = {
          tokenId: tokenId,
          tokenURI,
          price,
          tokenOwner: walletAddress,
          categories,
        };
        nftList.push(nft);
      }
      globalDispatch(setUserNFTS(nftList));
    } catch (error) {
      console.error("Error getting the NFT list", error);
    }
  };

  return {
    getNftList,
  };
}
