import { useContext, useEffect, useMemo, useState } from "react";
import MissionBox, {
	IMission,
} from "../../components/mission/missionBox/MissionBox";
import "./MissionsPage.css";
import { GlobalContext } from "../../contexts/GlobalContext";
import { setAppSection } from "../../store/globalActions";
import MissionModal, {
	IModalTextElements,
	IVisualElement,
} from "../../components/mission/missionModal/MissionModal";
import {
	MISSION_MODAL_BACKGROUNDS,
	MISSION_MODAL_TEXT_ELEMETS,
	MISSION_MODAL_VISUAL_ELEMENTS,
	MissionId,
} from "./MissionsPage.models";

const MissionsPage: React.FC = () => {
	const { globalDispatch, globalStore } = useContext(GlobalContext);

	const [showModal, setShowModal] = useState(false);
	const [modalBackground, setModalBackground] = useState("");
	const [modalVisualElements, setModalVisualElements] =
		useState<IVisualElement[]>();
	const [modalTextElements, setModalTextElements] =
		useState<IModalTextElements>();

	const missions: IMission[] = useMemo<IMission[]>(() => {
		return globalStore.application.missions;
	}, []);

	const configModal = (mission: IMission) => {
		// console.log(MISSION_MODAL_BACKGROUNDS['faucet'])
		const background = MISSION_MODAL_BACKGROUNDS[mission.id];
		setModalBackground(background);

		const visualElements = MISSION_MODAL_VISUAL_ELEMENTS[mission.id];
		setModalVisualElements(visualElements);

		const textElements = MISSION_MODAL_TEXT_ELEMETS[mission.id];
		setModalTextElements(textElements)
		setShowModal(true);
	};

	useEffect(() => {
		globalDispatch(setAppSection("Missions"));
	}, []);

	return (
		<div className="missions-page-container">
			
			<div className="background"></div>
			<div className="missions-container">
				{missions.map((mission, index) => (
					<MissionBox mission={mission} key={index} configModal={configModal} />
				))}
			</div>

			{showModal && (
				<MissionModal
					background={modalBackground}
					setShowModal={setShowModal}
					foregroundVisualElement={modalVisualElements}
					textElements={modalTextElements}
				/>
			)}
		</div>
	);
};

export default MissionsPage;
