import { ethers } from "ethers";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import { getContract } from "../services/kiichain";

import inkiiContract from "../abi/inkii.json";
import {
  setAppInkiiContract,
  setUserInkiiBalance,
} from "../store/globalActions";

export function useInkiiContract() {
  const contractAddress = process.env.REACT_APP_INKII_CONTRACT_ADDRESS;
  const contractABI = inkiiContract;

  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const { globalStore, globalDispatch } = useContext(GlobalContext);

  useEffect(() => {
    const signer = globalStore.user.signer;
    const contractInstance = getContract(
      contractAddress!,
      contractABI,
      signer!
    );
    setContract(contractInstance);
    globalDispatch(setAppInkiiContract(contractInstance));
  }, [globalStore.user.signer]);

  const getBalance = async () => {
    try {
      const balance = await contract?.balanceOf(globalStore.user.userAddress);
      const balanceNumber = ethers.formatUnits(balance, 18);
      globalDispatch(setUserInkiiBalance(balanceNumber));
    } catch (error) {
      console.error("Error getting the Inkii Balance", error);
    }
  };

  return {
    getBalance,
  };
}
