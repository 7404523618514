import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PresigningPage from "./pages/presigningPage/PresigningPage";
import ComingSoonPage from "./pages/comingSoonPage/ComingSoonPage";
import { WalletConnectContext } from "./contexts/WalletConnectContext";

import "./styles/variables.css";
import ConsolePage from "./pages/consolePage/ConsolePage";
import { GlobalReducerProvider } from "./contexts/GlobalContext";
import MissionsPage from "./pages/missionsPage/MissionsPage";
import { BanditProvider } from "./contexts/BanditContext";

const App: React.FC = () => {
  return (
    <GlobalReducerProvider>
      <WalletConnectContext>
        <BanditProvider>
          <Router>
            <Routes>
              <Route path="/" element={<PresigningPage />} />
              <Route path="/coming" element={<ComingSoonPage />} />
              <Route path="/home" element={<ConsolePage />} />
              <Route path="/missions" element={<MissionsPage />} />
            </Routes>
          </Router>
        </BanditProvider>
      </WalletConnectContext>
    </GlobalReducerProvider>
  );
};

export default App;
