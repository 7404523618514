import React from "react";
import PhaseBox from "../phaseBox/PhaseBox";
import "./PhaseBoxGroup.css";
import { useTranslation } from "react-i18next";
const PhaseBoxGroup: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="phaseGroup">
      <PhaseBox
        frontTitle={t("coming.phases.title1")}
        frontDescription={t("coming.phases.frontDescription1")}
        isNext={true}
        backDescription={t("coming.phases.backDescription1")}
      />
      <PhaseBox
        frontTitle={t("coming.phases.title2")}
        frontDescription={t("coming.phases.frontDescription2")}
        disabled={true}
        backDescription={t("coming.phases.backDescription2")}
      />
      <PhaseBox
        frontTitle={t("coming.phases.title3")}
        frontDescription={t("coming.phases.frontDescription3")}
        disabled={true}
        backDescription={t("coming.phases.backDescription3")}
      />
      <PhaseBox
        frontTitle={t("coming.phases.title4")}
        frontDescription={t("coming.phases.frontDescription4")}
        disabled={true}
        backDescription={t("coming.phases.backDescription4")}
      />
      <PhaseBox
        frontTitle={t("coming.phases.title5")}
        frontDescription={t("coming.phases.frontDescription5")}
        disabled={true}
        backDescription={t("coming.phases.backDescription5")}
      />
    </div>
  );
};

export default PhaseBoxGroup;
