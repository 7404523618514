import { useContext, useEffect, useState } from "react";
import { web3ModalContext } from "../../../contexts/WalletConnectContext";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  setAppError,
  setPreSignUpRegister,
  setUserAddress,
  setUserKiiBalance,
  setUserProvider,
  setUserShipName,
  setUserSigner,
} from "../../../store/globalActions";
import { useContract } from "../../../hooks/useContract";
import { BrowserProvider } from "ethers";
import { useWeb3Modal, useWeb3ModalProvider } from "@web3modal/ethers/react";
import { useInkiiContract } from "../../../hooks/useInkiiToken";
import { useNftContract } from "../../../hooks/useNftContract";
import { useTranslation } from "react-i18next";

interface props {
  className: string;
}

const ConnectWallet: React.FC<props> = ({ className }) => {
  const modalContext = useContext(web3ModalContext);
  const modal = modalContext?.modal;
  const { globalStore, globalDispatch } = useContext(GlobalContext);
  const { getUser } = useContract();
  const { t } = useTranslation();
  const { walletProvider } = useWeb3ModalProvider();
  const { getBalance } = useInkiiContract();
  const { getNftList } = useNftContract();
  const [buttonMessage, setButtonMessage] = useState<string>(
    t("utils.connectWallet.connect")
  );

  useEffect(() => {
    if (globalStore.user.userAddress !== "") {
      setButtonMessage(t("utils.connectWallet.connected"));
    }
  }, [globalStore.user.userAddress]);

  useEffect(() => {
    setButtonMessage(t("utils.connectWallet.connect"));
  }, [globalStore.application.language]);

  useEffect(() => {
    const fetchData = async () => {
      await getBalance();
    };

    if (globalStore.application.inkiiContract) {
      fetchData();
    }
  }, [globalStore.application.inkiiContract]);

  useEffect(() => {
    const fetchData = async () => {
      await getNftList();
    };

    if (globalStore.application.nftContract) {
      fetchData();
    }
  }, [globalStore.application.nftContract]);

  async function connectWallet() {
    try {
      await modal?.open();

      let isConnected = modal?.getIsConnectedState();
      const timeout = 30000; // max 30s to login
      const interval = 500; // each 500ms check whether the user login
      let elapsedTime = 0;

      while (!isConnected && elapsedTime < timeout) {
        await new Promise((resolve) => setTimeout(resolve, interval));
        isConnected = modal?.getIsConnectedState();
        elapsedTime += interval;
      }

      if (isConnected) {
        // Close WalletConnect modal
        await modal?.close();

        // Create provider and signer
        const provider = new BrowserProvider(walletProvider!);
        const signer = await provider.getSigner();
        globalDispatch(setUserProvider(provider));
        globalDispatch(setUserSigner(signer));

        // Get wallet address
        const walletAddress = await signer.getAddress();
        globalDispatch(setUserAddress(walletAddress));

        // Get Kii balance
        const balance = (await provider.getBalance(walletAddress)).toString();
        globalDispatch(setUserKiiBalance(balance));

        // Get ship name
        const userData = await getUser(walletAddress!);
        if (!userData?.shipName) {
          globalDispatch(setPreSignUpRegister(true));
          return; // User have not registered yet
        }

        if (!userData?.agreeTerms) {
          console.error("Error getting the user information.");
          globalDispatch(setAppError("Error getting the user information."));
        }

        globalDispatch(setUserShipName(userData?.shipName!));
        globalDispatch(setPreSignUpRegister(true));
        return;
      } else {
        console.log("The time for login has finished.");
        globalDispatch(setAppError("The time for login has finished."));
      }
    } catch (error) {
      console.error("Error openning Wallet Connect modal", error);
      globalDispatch(
        setAppError(`Error openning Wallet Connect modal, ${error}`)
      );
    }
  }
  return (
    <button className={className} onClick={connectWallet}>
      {buttonMessage}
    </button>
  );
};

export default ConnectWallet;
