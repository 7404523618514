import React, { useContext, useEffect, useRef, useState } from "react";
import PresigningForm from "../../components/presigning/PresignedForm/PresigningForm";
import FAQ from "../../components/presigning/faq/FAQ";
import "./PresigningPage.css";
import PresignedModal from "../../components/presigning/PresignedModal/PresignedModal";
import Header from "../../components/common/header/Header";
import Loading from "../../components/common/loading/Loading";
import Modal from "../../components/common/modal/Modal";
import LoginForm from "../../components/presigning/LoginForm/LoginForm";
import VideoWithPlaceholder from "../../components/presigning/greetingVideo/GreetingVideo";
import Footer from "../../components/common/footer/Footer";
import CoinGif from "../../components/common/coinGif/CoinGif";
import { GlobalContext } from "../../contexts/GlobalContext";
import { setAppSection } from "../../store/globalActions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ScrollIcon from "../../components/common/scrollIcon/ScrollIcon";

const PresigningPage: React.FC = () => {
  const [isModalActive, setIsModalActive] = useState<boolean>(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] =
    useState<boolean>(false);
  const { globalStore, globalDispatch } = useContext(GlobalContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    globalDispatch(setAppSection("pre-signup"));
  }, []);

  useEffect(() => {
    if (globalStore.user.shipName !== "") {
      // navigate("/coming");
    }
  }, [globalStore.user.shipName]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(false);
          } else {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <div className="presigning-page-container">
      <div className="solid-background"></div>
      <div className="pre-sign-background"></div>

      {/* Greeting video */}
      {/* <VideoWithPlaceholder></VideoWithPlaceholder> */}

      <div className="header-container">
        <Header />
      </div>
      <div className="foreground-image-container">
        <div className="foreground-image"></div>
      </div>
      <div className={"content-container"}>
        <div className="title-image"></div>
        <div className="subtitle">
          <div className="highlighted-text">
            {t("pre-sign-up.logIn.subtitle1")}
          </div>
          <div>{t("pre-sign-up.logIn.subtitle2")}</div>
        </div>

        <div className="formText">
          {globalStore.application.preSignUp.register && (
            <PresigningForm
              setIsModalActive={setIsModalActive}
              setIsAlreadyRegistered={setIsAlreadyRegistered}
            />
          )}

          {!globalStore.application.preSignUp.register && (
            <LoginForm></LoginForm>
          )}
        </div>
        <CoinGif></CoinGif>
      </div>

      <div className="faq">
        <FAQ />
      </div>

      {/* Scroll icon */}
      {isVisible && <ScrollIcon />}

      {/* Footer */}
      <div className="footer-container-page" ref={footerRef}>
        <Footer />
      </div>

      {/* Modal T&C component */}
      {isModalActive && (
        <>
          <div className="modal-overlay"></div>
          <div className="modalTC">
            <PresignedModal setIsModalActive={setIsModalActive} />
          </div>
        </>
      )}
      {/* Loading component */}
      {globalStore.application.loading && (
        <>
          <div className="modal-overlay"></div>
          <div className="loading-modal">
            <Loading />
          </div>
        </>
      )}
      {/* Modal of User already registered */}
      {isAlreadyRegistered && (
        <>
          <div className="modal-overlay"></div>
          <div className="modal">
            <Modal
              content={t("pre-sign-up.registeredModal.title")}
              title={t("pre-sign-up.registeredModal.content")}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PresigningPage;
