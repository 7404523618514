import "./Button.css"


interface props {
    isGreen:boolean
    text:string
}

const Button:React.FC<props> = ({text, isGreen})=>{

    return (
        <div className={`button ${isGreen? 'green':'cyan'}`}>{text}</div>
    )
}

export default Button