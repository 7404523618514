import { BrowserProvider, ethers } from "ethers";
import { Action } from "../interfaces/store";

// Aplication actions
export const GLOBAL_SET_APP_SECTION = "[Global] Set app section";
export const GLOBAL_SET_APP_LOADING = "[Global] Set app loading";
export const GLOBAL_SET_APP_LOADED = "[Global] Set app loaded";
export const GLOBAL_SET_APP_ERROR = "[Global] Set app error";
export const GLOBAL_SET_APP_INKII_CONTRACT = "[Global] Set app inkii contract";
export const GLOBAL_SET_APP_NFT_CONTRACT = "[Global] Set app NFT contract";
export const GLOBAL_SET_APP_LANGUAGE = "[Global] Set app language";

// User actions
export const GLOBAL_SET_USER_ADDRESS = "[Global] Set user address";
export const GLOBAL_SET_USER_INKII_BALANCE = "[Global] Set user Inkii Balance";
export const GLOBAL_SET_USER_KII_BALANCE = "[Global] Set user Kii Balance";
export const GLOBAL_SET_USER_NFTS = "[Global] Set user NFTs";
export const GLOBAL_SET_USER_SHIP_NAME = "[Global] Set user ship name";
export const GLOBAL_SET_USER_PROVIDER = "[Global] Set user provider";
export const GLOBAL_SET_USER_SIGNER = "[Global] Set user signer";

// Console Actions
export const GLOBAL_SET_PHASE_PROGRESS = "[Global] Set phase progress";

// Pre-signUp Actions
export const GLOBAL_SET_PRESIGNUP_REGISTER = "[Global] Set pre-signup Register";

// Application actions
export const setAppSection = (section: string): Action => ({
  type: GLOBAL_SET_APP_SECTION,
  payload: section,
});

export const setAppLoading = (): Action => ({
  type: GLOBAL_SET_APP_LOADING,
  payload: undefined,
});

export const setAppLoaded = (): Action => ({
  type: GLOBAL_SET_APP_LOADED,
  payload: undefined,
});

export const setAppError = (errorMessage: string): Action => ({
  type: GLOBAL_SET_APP_ERROR,
  payload: errorMessage,
});

export const setAppInkiiContract = (contract: ethers.Contract): Action => ({
  type: GLOBAL_SET_APP_INKII_CONTRACT,
  payload: contract,
});

export const setAppNftContract = (contract: ethers.Contract): Action => ({
  type: GLOBAL_SET_APP_NFT_CONTRACT,
  payload: contract,
});

export const setAppLanguage = (language: string): Action => ({
  type: GLOBAL_SET_APP_LANGUAGE,
  payload: language,
});

// User actions
export const setUserAddress = (userAddress: string): Action => ({
  type: GLOBAL_SET_USER_ADDRESS,
  payload: userAddress,
});

export const setUserInkiiBalance = (balance: string): Action => ({
  type: GLOBAL_SET_USER_INKII_BALANCE,
  payload: balance,
});

export const setUserKiiBalance = (balance: string): Action => ({
  type: GLOBAL_SET_USER_KII_BALANCE,
  payload: balance,
});

export const setUserNFTS = (collection: any[]): Action => ({
  type: GLOBAL_SET_USER_NFTS,
  payload: collection,
});

export const setUserShipName = (shipName: string): Action => ({
  type: GLOBAL_SET_USER_SHIP_NAME,
  payload: shipName,
});

export const setUserProvider = (provider: BrowserProvider): Action => ({
  type: GLOBAL_SET_USER_PROVIDER,
  payload: provider,
});

export const setUserSigner = (signer: ethers.JsonRpcSigner): Action => ({
  type: GLOBAL_SET_USER_SIGNER,
  payload: signer,
});

// Console Actions
export const setPhaseProgress = (progress: number): Action => ({
  type: GLOBAL_SET_PHASE_PROGRESS,
  payload: progress,
});

// Pre-signup actions
export const setPreSignUpRegister = (state: boolean): Action => ({
  type: GLOBAL_SET_PRESIGNUP_REGISTER,
  payload: state,
});
