import React, { useContext, useEffect, useReducer, useState } from "react";
import logo from "../../../assets/images/logo.png";
import languageIcon from "../../../assets/images/language.png";
import "./Header.css";
import { Link } from "react-router-dom";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { setAppLanguage, setAppSection } from "../../../store/globalActions";
import i18n from "../../../language/i18n";

const Header: React.FC = () => {
  const { globalStore, globalDispatch } = useContext(GlobalContext);
  const [language, setLanguage] = useState("es");

  useEffect(() => {
    setLanguage(globalStore.application.language);
    i18n.changeLanguage(globalStore.application.language);
  }, [globalStore.application.language]);

  const changeLanguage = () => {
    if (language == "en") {
      globalDispatch(setAppLanguage("es"));
    } else {
      globalDispatch(setAppLanguage("en"));
    }
  };

  return (
    <header className="header">
      <Link to={"/"}>
        <img
          src={logo}
          alt="KiiChain Logo"
          className="header-logo"
          onClick={() => globalDispatch(setAppSection(""))}
        />
      </Link>
      <div className="language-icon" onClick={changeLanguage}>
        <img src={languageIcon} alt="change language icon" />
        <label>{language}</label>
      </div>
    </header>
  );
};

export default Header;
