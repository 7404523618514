import React, { useContext, useReducer, useState } from "react";
import "./PresigningForm.css";
import { UserData } from "../../../interfaces/entities";
import { useContract } from "../../../hooks/useContract";
import { useNavigate } from "react-router-dom";
import { generateReferralCode } from "../../../utils/validateForm";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  setAppError,
  setAppLoaded,
  setAppLoading,
  setPreSignUpRegister,
} from "../../../store/globalActions";
import { useTranslation } from "react-i18next";

interface props {
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAlreadyRegistered: React.Dispatch<React.SetStateAction<boolean>>;
}

const PresigningForm: React.FC<props> = ({
  setIsModalActive,
  setIsAlreadyRegistered,
}) => {
  const { registerUser, getUser, isShipNameTaken } = useContract();
  const navigate = useNavigate();
  const { globalStore, globalDispatch } = useContext(GlobalContext);
  const { t } = useTranslation();

  const [formState, setFormState] = useState<UserData>({
    shipName: globalStore.user.shipName,
    walletAddress: globalStore.user.userAddress,
    referralCode: "",
    agreeTerms: false,
    externalReferralCode: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      // Start loading pagen and make smart contract's request
      globalDispatch(setAppLoading());

      // Check if user is already registered
      const userData = await getUser(formState.walletAddress);

      if (userData?.agreeTerms) {
        // User already registered
        globalDispatch(setAppLoaded());
        setIsAlreadyRegistered(true);
        await new Promise((resolve) => setTimeout(resolve, 1500));
        setIsAlreadyRegistered(false);
        navigate("/coming");
        return;
      }

      // Validate the ship name (must be unique)
      const isTaken = await isShipNameTaken(formState.shipName);
      if (isTaken) {
        globalDispatch(setAppLoaded());
        globalDispatch(
          setAppError("Ship name already taken, please select another")
        );
        return;
      }

      // Create referral code
      const code = generateReferralCode(
        formState.shipName,
        formState.walletAddress
      );
      formState.referralCode = code;
      const success = await registerUser(formState);

      // Stop loading and redirect if request was success
      globalDispatch(setAppLoaded());
      if (success) {
        navigate("/coming");
        return;
      }
    } catch (error) {
      globalDispatch(setAppLoaded());
      globalDispatch(setAppError("Error submiting the information"));
    }
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div className="form-group">
        <label className="form-label">Do you have a Referral code?</label>

        <input
          type="text"
          name="externalReferralCode"
          placeholder={t("pre-sign-up.logIn.referralCode")}
          value={formState.externalReferralCode}
          onChange={handleChange}
          className="form-input"
        />

        <label className="form-label">
          Name of your ship in this amazing adventure
        </label>
        <input
          type="text"
          name="shipName"
          placeholder={t("pre-sign-up.logIn.shipName")}
          value={formState.shipName}
          onChange={handleChange}
          className="form-input"
          required
        />
        {globalStore.application.error && (
          <p className="error-message">
            {globalStore.application.errorMessage}
          </p>
        )}

        <div className="advice-message">
          <label>{t("pre-sign-up.logIn.adviceMessage")}</label>
        </div>
        <div className="checkbox-group">
          <label>{t("pre-sign-up.logIn.agreeT&C")}</label>
          <input
            type="checkbox"
            name="agreeTerms"
            id="agreeTerms"
            checked={formState.agreeTerms}
            onChange={handleChange}
            required
          />
          <label htmlFor="agreeTerms" className="checkbox-label">
            <a href="#" onClick={() => setIsModalActive(true)}>
              {t("pre-sign-up.logIn.termsAndConditions")}
            </a>
          </label>
        </div>
        <button type="submit" className="submit-button">
          <div className="submit-title">
            {t("pre-sign-up.logIn.submitTitle")}
          </div>
          <div className="submit-description">
            {t("pre-sign-up.logIn.submitSubtitle")}
          </div>
        </button>
        <div className="advice-message"></div>
      </div>
    </form>
  );
};

export default PresigningForm;
