import { useTranslation } from "react-i18next";
import "./PresignedModal.css";
import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { termsAndConditionsEN, termsAndConditionsES } from "../../../data/t&c";

interface props {
  setIsModalActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PresignedModal: React.FC<props> = ({ setIsModalActive }) => {
  const { t } = useTranslation();
  const { globalStore } = useContext(GlobalContext);
  const handleModal = (value: boolean): void => {
    setIsModalActive(false); // Close the modal even the tyc were rejected
  };

  return (
    <>
      <div className="modalContainer">
        <div className="modalTitle">
          <h1>{t("pre-sign-up.termsAndConditions.title")}</h1>
        </div>

        <div className="modalContent">
          <label htmlFor="termsConditions">
            {t("pre-sign-up.termsAndConditions.subtitle")}
          </label>
        </div>

        {globalStore.application.language == "en" && (
          <div className="textContainer">
            <div dangerouslySetInnerHTML={{ __html: termsAndConditionsEN }} />
          </div>
        )}

        {globalStore.application.language == "es" && (
          <div className="textContainer">
            <div dangerouslySetInnerHTML={{ __html: termsAndConditionsES }} />
          </div>
        )}

        <div className="buttonContainer">
          <button className="cancelButton" onClick={() => handleModal(false)}>
            {t("pre-sign-up.termsAndConditions.cancelButton")}
          </button>
          <button className="acceptButton" onClick={() => handleModal(true)}>
            {t("pre-sign-up.termsAndConditions.ApproveButton")}
          </button>
        </div>
      </div>
    </>
  );
};

export default PresignedModal;
